<!-- @format -->

<template>
  <b-card class="mb-3" header-class="d-flex justify-content-between align-items-center">
    <!-- <b-form-select v-model="pageSize" :options="options" /> -->
    <b-modal
      id="getActiveTeamTp"
      ref="modal"
      title="TP查看"
    >
      <b-table :fields="getTeamTpFiele" :items="getTeamTp" empty-text="暂无数据" show-empty />

    </b-modal>
    <b-row>
      <b-col md="2" class="mb-3">
        <div class="d-flex" style="align-items: center">
          <span style="width: 60px; display: flex">每页:</span>
          <b-form-select v-model="pageSize" :options="options" />
        </div>
      </b-col>
      <!-- 时间区段选择 -->
      <b-col md="2" class="mb-3 d-flex">
        <v-select
          v-model="year"
          :clearable="false"
          :options="years"
          :reduce="option => option.value"
          placeholder="Year"
          style="width:100%;"
        />
      </b-col>
      <b-col md="2" class="mb-3 d-flex">
        <v-select
          v-model="month"
          :clearable="false"
          :options="months"
          :reduce="option => option.value"
          placeholder="Month"
          style="width:100%;"
          :disabled="!year"
        />
      </b-col>

      <!--      搜索id-->
      <b-col cols="7" class="d-flex">
        <div class="mr-1">
          <b-form-input v-model="id" class="d-inline-block" placeholder="Member id" />
        </div>
        <div>
          <b-form-input v-model="real_name" class="d-inline-block" placeholder="Member name" />
        </div>
      </b-col>
    </b-row>
    <b-tabs content-class="mt-3" @input="chan">
      <b-tab title="个人TP">
        <div class="total-active-tp">
          <strong style="font-size: 1.5em;">Total Active TP: {{ totalActiveTP }}</strong>
  </div>
        <b-table :fields="itemsPerson" :items="itemsPersonItem" empty-text="暂无个人TP数据" show-empty />
      </b-tab>
      <b-tab title="基础团队TP">

        <b-table :fields="itemsPerson2" :items="itemsPersonItem" empty-text="暂无基础团队TP数据" show-empty>
          <!--          解构active_tp -->
          <template #cell(active_tp)="data">
            <div class="font-weight-bold" @click="getActiveTeamTp(data)"> {{ data.value }}</div>
          </template>

        </b-table>
        <!--   弹窗     -->
      </b-tab>
      <b-tab title="超级团队TP">
        <b-table :fields="itemsPerson2" :items="itemsPersonItem" empty-text="暂无超级团队TP数据" show-empty>
          <template #cell(active_tp)="data">
            <div class="font-weight-bold" @click="getActiveTeamTp(data)"> {{ data.value }}</div>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="无限团队TP">
        <b-table :fields="itemsPerson2" :items="itemsPersonItem" empty-text="暂无 无限团队TP 数据" show-empty>
          <template #cell(active_tp)="data">
            <div class="font-weight-bold" @click="getActiveTeamTp(data)"> {{ data.value }}</div>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
    <b-pagination v-model="currentPage" :total-rows="total" :per-page="pageSize" aria-controls="my-table" class="my-0" align="center" />
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard, BTable, BFormSelect, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getCurrentInstance, nextTick, reactive, ref, watch } from '@vue/composition-api'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BTabs,
    BTab,
    BTable,
    BCard,
    BFormSelect,
    BPagination,
    vSelect
  },
  setup() {
    const years = [
      { value: '2022', label: '2022' },
      { value: '2023', label: '2023' },
      { value: '2024', label: '2024' }
    ]
    const months = [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
      { value: '6', label: '6' },
      { value: '7', label: '7' },
      { value: '8', label: '8' },
      { value: '9', label: '9' },
      { value: '10', label: '10' },
      { value: '11', label: '11' },
      { value: '12', label: '12' },
    ]

    const { proxy } = getCurrentInstance()
    const itemsPerson = [
      {
        key: 'key',
        label: '排名',
      },
      {
        key: 'real_name',
        label: '姓名',
      },
      {
        key: 'active_tp',
        label: '生效TP',
      },
      {
        key: 'pid',
        label: '上线ID',
      },
      {
        key: 'parent_name',
        label: '上线姓名',
      },
    ]
    const itemsPerson2 = [
      {
        key: 'id',
        label: '排名',
      },
      {
        key: 'real_name',
        label: '队长',
      },
      {
        key: 'active_tp',
        label: '团队生效TP',
      },
      {
        key: 'pid',
        label: '上线ID',
      },
      {
        key: 'parent_name',
        label: '上线会员姓名',
      },
    ]
    const itemsPersonItem = ref([])

    const normalTeamTp = [
      {
        key: 'id',
        label: '排名',
      },
      {
        key: 'name',
        label: '姓名',
      },
      {
        key: 'tp',
        label: 'TP',
      },
      {
        key: 'action',
        label: '操作',
      },
    ]

    const superTeamTp = [
      {
        key: 'id',
        label: '排名',
      },
      {
        key: 'name',
        label: '姓名',
      },
      {
        key: 'tp',
        label: 'TP',
      },
      {
        key: 'action',
        label: '操作',
      },
    ]

    const travTeamTp = [
      {
        key: 'id',
        label: '排名',
      },
      {
        key: 'name',
        label: '姓名',
      },
      {
        key: 'tp',
        label: 'TP',
      },
      {
        key: 'action',
        label: '操作',
      },
    ]

    const getTeamTpFiele = [
      {
        key: 'id',
        label: '排名',
      },
      {
        key: 'real_name',
        label: '姓名',
      },
      {
        key: 'tp',
        label: '生效tp',
      },

    ]

    const tabs = reactive([
      { title: '个人TP', content: 'Tab 1 content' },
      { title: '基础团队TP', content: 'Tab 2 content' },
      { title: '超级团队TP', content: 'Tab 3 content' },
      { title: '无限团队TP', content: 'Tab 3 content' },
    ])

    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altInput: true,
      dateFormat: 'Y-m',
    }

    const pageSize = ref(200)
    const currentPage = ref(1)
    const total = ref(0)
    const options = [
      { value: 10, text: '10' },
      { value: 20, text: '20' },
      { value: 50, text: '50' },
      { value: 100, text: '100' },
    ]
    const totalActiveTP = ref(0); // 在此处添加
    // 四个表格的数据,四个方法调用
    const year = ref('')
    const month = ref('')
    const id = ref('')
    const real_name = ref('')
    const cate_id = ref('')
    const fetchData = async () => {
      nextTick(() => {
        axiosIns
          // .get('order/tp-all', {
          .get('order/tp-all', {
            params: {
              cate_id: cate_id.value,
              year: year.value,
              month: month.value,
              page_size: pageSize.value,
              page: currentPage.value,
              id: id.value,
              real_name: real_name.value,
            },
          })
          .then(res => {
            itemsPersonItem.value = res.data?.list;
        total.value = res.data?.total;
        totalActiveTP.value = res.data?.totalActiveTP;
          })
      })
    }

    watch([year, month, pageSize, id, real_name, currentPage], () => {
      fetchData()
    })

    const getTeamTp = ref([])
    const getActiveTeamTp = data => {
      getTeamTp.value = []
      // console.log(data)
      axiosIns.get(`order//click-get-tp?user_id=${data.item.user_id}&cate=${cate_id.value}&year=${year.value}&month=${month.value}`).then(res => {
        getTeamTp.value = res.data
        proxy.$bvModal.show('getActiveTeamTp')
      })
      proxy.$bvModal.show('getActiveTeamTp')
    }

    const chan = val => {
      cate_id.value = val + 1
      itemsPersonItem.value = []
      fetchData()
    }

    return {
      getTeamTpFiele,
      getTeamTp,
      getActiveTeamTp,
      cate_id,
      currentPage,
      total,
      config,
      fetchData,
      options,
      id,
      real_name,
      year,
      month,
      tabs,
      chan,
      pageSize,

      itemsPerson,
      itemsPersonItem,
      itemsPerson2,

      normalTeamTp,
      superTeamTp,
      travTeamTp,

      years,
      months,
      totalActiveTP, 
    }
  },
}
</script>

<style></style>
